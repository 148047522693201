import React from 'react';
import { ParseAbbreviations } from '../../ParseAbbreviations';
import { getLinkTargetValue } from './SectionComponentHelper';
import { CMSLink } from '@components/CMSLink';
import { DangerouslySetInnerHTML } from './DangerouslySetInnerHTML';
import { tryDecodeURI } from '@helpers/string';
import { Countdown } from '../../Countdown';
import { Card } from '../../Cards/Card';
import { cardTypes } from '../../../helpers/ObjectTypeModels/Card';
import { Testimonial } from './Testimonial';
import { CustomSection } from '@components/SectionComponents/CustomSection';
import { ColumnCount, FlexAlignment, SectionColumn } from '@models/column';
import { Heading } from '@web-for-marketing/react-ui';
import { RichTextContent } from '@components/RichTextContent';
import { TitleBodyButtons } from '@components/SectionComponents/TitleBodyButtons';
import { CtaBannerCarousel } from '@components/SectionComponents/CtaBannerCarousel';
import { MediaType } from './MediaType';
import loadable from '@loadable/component';
import { ClassNames } from '@emotion/react';
import { PopupPicture } from '@components/PopupPicture';
import { Grid2 } from '@mui/material';
import { richTextHtmlStyles } from '../styleObjects/richTextHtmlStyles';

const CustomFormLoadable = loadable(() => import('@components/SectionComponents/CustomForm'));

type RenderColumnTypesProps = {
    column: SectionColumn;
    lazyLoad?: boolean;
    headerSection?: boolean;
    columnCount?: ColumnCount;
    horizontalAlignment?: FlexAlignment;
};

export function RenderColumnTypes({
    column,
    lazyLoad,
    headerSection,
    columnCount,
    horizontalAlignment = 'flex-start',
}: RenderColumnTypesProps): JSX.Element | null {
    function getContent(): JSX.Element | null {
        const { type, content } = column;

        switch (type) {
            case 'title-body-buttons':
                return (
                    <TitleBodyButtons
                        content={content}
                        textColor={content.textColor}
                        headerSection={headerSection}
                        horizontalAlignment={horizontalAlignment}
                    />
                );
            case 'cta-banner-carousel':
                return <CtaBannerCarousel content={content} lazyLoad={lazyLoad} />;
            case 'layered-content':
                return (
                    <>
                        {content.subtitle ? (
                            <Heading
                                variant='h4'
                                color='tertiary'
                                component='h3'
                                data-testid='layeredContentColumn-subtitle'
                            >
                                {content.subtitle}
                            </Heading>
                        ) : null}
                        {content.title ? (
                            <Heading
                                variant='h3'
                                weight='regular'
                                css={{ marginBottom: '3rem' }}
                                data-testid='layeredContentColumn-title'
                            >
                                <ParseAbbreviations content={content.title} />
                            </Heading>
                        ) : null}
                        {content.body ? (
                            <RichTextContent
                                color={content.textColor || 'tertiary'}
                                data-testid='layeredContentColumn-body'
                                content={ParseAbbreviations({
                                    content: content.body,
                                    stringFormat: true,
                                })}
                                css={{ marginBottom: '3rem' }}
                            />
                        ) : null}
                        {content.linkPath && content.linkText ? (
                            <CMSLink
                                href={content.linkPath}
                                data-testid='layeredContentColumn-link'
                                target={getLinkTargetValue(content.linkTarget)}
                                variant='text'
                                type='button'
                                aria-label={content.ariaLabel || ''}
                            >
                                {content.linkText}
                            </CMSLink>
                        ) : null}
                    </>
                );
            case 'image':
                return content.image.imagePath || content.image.mobilePath ? (
                    <ClassNames>
                        {({ css }) => (
                            <PopupPicture
                                data-testid='imageColumn'
                                imagePath={content.image.imagePath}
                                mobilePath={content.image.mobilePath}
                                imageZoom={content.image.imageZoom}
                                pictureProps={{ className: css({ height: 'auto !important' }) }}
                                alt={content.image.imageAlt}
                                mobileAlt={content.image.mobileAlt}
                                lazyLoad={lazyLoad}
                                aspectRatio={content.image.imageAspectRatio}
                                mobileAspectRatio={content.image.mobileImageAspectRatio}
                                preload={!lazyLoad}
                            />
                        )}
                    </ClassNames>
                ) : null;
            case 'video':
            case 'media':
                return <MediaType content={content} lazyLoad={lazyLoad} />;
            case 'hover-card':
            case 'cards':
                return (
                    <Grid2 container columnSpacing={'4%'} rowSpacing={'2rem'}>
                        {Array.isArray(content)
                            ? content.map((content, index) => {
                                const cardContent = {
                                    ariaLabel: content.ariaLabel,
                                    linkTarget: content.linkTarget,
                                    image: {
                                        lazyLoad: lazyLoad,
                                        ...content.image,
                                    },
                                };

                                return (
                                    <Grid2 size={{ xs: 6, md: 4 }} key={index}>
                                        <Card
                                            card={{
                                                ...cardTypes.SMALL_NO_CONTENT_TILE,
                                                content: cardContent,
                                            }}
                                            href={content.linkPath}
                                            data-testid={`hoverCard${index}`}
                                        />
                                    </Grid2>
                                );
                            })
                            : null}
                    </Grid2>
                );
            case 'html':
                return <DangerouslySetInnerHTML html={tryDecodeURI(content)} css={richTextHtmlStyles.rawHTML()} />;
            case 'testimonial':
                return <Testimonial content={content} lazyLoad={lazyLoad} columnCount={columnCount} />;
            case 'countdown':
                return <Countdown utcDateTime={content.utcDateTime} />;
            case 'custom-section':
                return (
                    <CustomSection
                        component={content.component}
                        params={content.params}
                        lazyLoad={lazyLoad}
                        headerSection={headerSection}
                    />
                );
            case 'custom-form':
                return (
                    <CustomFormLoadable sectionData={content} lazyLoad={lazyLoad} embed headerSection={headerSection} />
                );
            default:
                return null;
        }
    }

    return column && column.content && column.type ? getContent() : null;
}
