import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { SubNavigationData } from '@models/section/subNavigationSection';
import { subNavigationStyles as classes } from '@components/styleObjects/SubNavigation';
import { CMSLink } from '@components/CMSLink';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { NewTabIcon } from '@web-for-marketing/react-ui';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { RightArrowIcon } from '@components/IconComponents/RightArrowIcon';
import { v2Colors } from '@web-for-marketing/react-ui';
import { SubNavigationMobile } from './SubNavigationMobile';
import { debounce } from '@helpers/debounceHelper';

export interface SubNavigationProps {
    sectionData: SubNavigationData;
}

export function SubNavigation({ sectionData }: SubNavigationProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const containerRef = useRef<HTMLDivElement>(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    useEffect(() => {
        const container = containerRef.current;

        if (!container) return;

        const resizeObserver = new ResizeObserver(() => {
            updateScrollArrows();
        });

        resizeObserver.observe(container);

        return () => {
            resizeObserver.unobserve(container);
        };
    }, []);

    const updateScrollArrows = (): void => {
        const container = containerRef.current;
        if (!container) return;

        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(container.scrollLeft + container.clientWidth < container.scrollWidth);
    };

    const handleScroll = (direction: 'left' | 'right'): void => {
        const container = containerRef.current;
        const adjustment = direction === 'left' ? -100 : 100;
        if (container) {
            container.scrollTo({
                left: container.scrollLeft + adjustment, // Adjust scroll amount as needed
                behavior: 'smooth',
            });
            updateScrollArrows();
        }
    };

    const handleScrollDebounced = debounce(updateScrollArrows, 100);

    return (
        <div css={classes.subNavigationContainer}>
            {isMobile ? (
                <SubNavigationMobile sectionData={sectionData} />
            ) : (
                <div css={classes.subNavigation}>
                    {showLeftArrow ? (
                        <div css={classes.arrowContainer}>
                            <Button
                                onClick={() => handleScroll('left')}
                                css={classes.arrowButton}
                                aria-hidden
                                data-testid='scroll-left-button'
                                tabIndex={-1}
                            >
                                <RightArrowIcon color={v2Colors.text.activePrimary} css={classes.flipArrow} />
                            </Button>
                        </div>
                    ) : null}
                    <Grid
                        container
                        width='auto'
                        flexWrap='nowrap'
                        ref={containerRef}
                        data-testid='link-container'
                        css={classes.linkItemContainer}
                        onScroll={handleScrollDebounced}
                    >
                        {sectionData.links.map((link, index) => (
                            <Grid item key={`${JSON.stringify(link)}-${index}`} display='flex'>
                                <CMSLink
                                    href={link.linkPath}
                                    target={getLinkTargetValue(link.linkTarget)}
                                    css={classes.linkItem}
                                >
                                    {link.linkText}
                                    {link.linkTarget ? (
                                        <NewTabIcon aria-hidden css={classes.smallLeftMarginIcon} />
                                    ) : null}
                                </CMSLink>
                            </Grid>
                        ))}
                    </Grid>
                    {showRightArrow ? (
                        <Grid item css={classes.arrowContainer}>
                            <Button
                                onClick={() => handleScroll('right')}
                                css={classes.arrowButton}
                                aria-hidden
                                tabIndex={-1}
                                data-testid='scroll-right-button'
                            >
                                <RightArrowIcon color={v2Colors.text.activePrimary} />
                            </Button>
                        </Grid>
                    ) : null}
                    {sectionData.buttons.length ? (
                        <>
                            <span data-testid='divider' css={classes.buttonDivider} />
                            <>
                                {sectionData.buttons.map(
                                    (
                                        {
                                            buttonText,
                                            buttonLinkPath,
                                            buttonType,
                                            buttonTarget,
                                            buttonClassName,
                                            ariaLabel,
                                            isPopout,
                                            popoutDisplayMode,
                                            popoutBackgroundImage,
                                            popoutCustomSection,
                                            params = [],
                                        },
                                        index
                                    ) =>
                                        buttonText ? (
                                            <Grid
                                                item
                                                xs={12}
                                                md='auto'
                                                css={classes.buttonContainer}
                                                key={`${index}${buttonText}${popoutCustomSection}`}
                                            >
                                                {isPopout ? (
                                                    <PopoutButton
                                                        text={buttonText}
                                                        variant={buttonType || 'contained'}
                                                        customSectionKey={popoutCustomSection}
                                                        displayMode={popoutDisplayMode}
                                                        backgroundImagePath={popoutBackgroundImage}
                                                        params={params}
                                                        aria-label={ariaLabel}
                                                        css={classes.buttonItem}
                                                    />
                                                ) : (
                                                    <CMSLink
                                                        type='button'
                                                        variant={buttonType || 'contained'}
                                                        href={buttonLinkPath}
                                                        target={getLinkTargetValue(buttonTarget)}
                                                        aria-label={ariaLabel}
                                                        buttonClassName={buttonClassName}
                                                        css={classes.buttonItem}
                                                    >
                                                        {buttonText}
                                                    </CMSLink>
                                                )}
                                            </Grid>
                                        ) : null
                                )}
                            </>
                        </>
                    ) : null}
                </div>
            )}
        </div>
    );
}
