import React from 'react';
import { SectionContainer } from './SectionContainer';
import { Heading, LazyLoadContainer, v2Colors, Text, V2Button } from '@web-for-marketing/react-ui';
import { Grid2, Stack } from '@mui/material';
import { RichTextContent } from '@components/RichTextContent';
import { OutlinedCheckmarkIcon } from '@components/IconComponents/OutlinedCheckmarkIcon';
import { BulletPointsHeaderSectionData } from '@models/section/bulletPointsHeaderSection';
import { PopoutButton } from '@components/PopoutCustomSection/PopoutButton';
import { getLinkTargetValue } from './helpers/SectionComponentHelper';
import { Picture } from '@components/Picture';

interface BulletPointsHeaderProps {
    sectionData: BulletPointsHeaderSectionData;
    lazyLoad: boolean;
    headerSection: boolean;
    hasBackground: boolean;
}

const classes = {
    list: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    },
} as const;

export function BulletPointsHeader({
    sectionData,
    lazyLoad,
    headerSection,
    hasBackground,
}: BulletPointsHeaderProps): JSX.Element {
    const { title, bulletPoints, buttons, image, label, headingVariant } = sectionData;

    return (
        <LazyLoadContainer
            data-testid='bulletPointsHeaderBackground'
            lazyload={lazyLoad}
            css={!hasBackground ? { backgroundColor: v2Colors.background.dark } : undefined}
        >
            <SectionContainer>
                <Grid2 container alignItems='center' spacing='24px'>
                    <Grid2 size={{ xs: 12, md: 5 }}>
                        <Stack spacing='16px'>
                            {label ? (
                                <Text variant='h5' color='inversePrimary' weight='medium'>
                                    {label}
                                </Text>
                            ) : null}
                            <Heading
                                variant={headingVariant}
                                component={headerSection ? 'h1' : 'h2'}
                                color='inversePrimary'
                            >
                                {title}
                            </Heading>
                            {bulletPoints.length ? (
                                <Stack component='ul' spacing='16px' css={classes.list}>
                                    {bulletPoints.map((bulletPoint, index) => (
                                        <Stack direction='row' component='li' key={index} spacing='8px'>
                                            <OutlinedCheckmarkIcon color={v2Colors.text.inversePrimary} />
                                            <RichTextContent content={bulletPoint} color='inversePrimary' />
                                        </Stack>
                                    ))}
                                </Stack>
                            ) : null}
                            {buttons.length ? (
                                <Stack direction='row' gap={2} flexWrap='wrap'>
                                    {buttons.map((button, index) =>
                                        button.isPopout ? (
                                            <PopoutButton
                                                key={`${button.buttonText}-${index}`}
                                                text={button.buttonText}
                                                variant={button.buttonType || 'primary'}
                                                color={button.buttonColor || 'blue'}
                                                customSectionKey={button.popoutCustomSection}
                                                displayMode={button.popoutDisplayMode}
                                                backgroundImagePath={button.popoutBackgroundImage}
                                                params={button.params}
                                                aria-label={button.ariaLabel}
                                                isV2
                                            />
                                        ) : (
                                            <V2Button
                                                key={`${button.buttonText}-${index}`}
                                                component='a'
                                                variant={button.buttonType || 'primary'}
                                                color={button.buttonColor || 'blue'}
                                                href={button.buttonLinkPath}
                                                target={getLinkTargetValue(button.buttonTarget)}
                                                aria-label={button.ariaLabel}
                                                className={button.buttonClassName}
                                            >
                                                {button.buttonText}
                                            </V2Button>
                                        )
                                    )}
                                </Stack>
                            ) : null}
                        </Stack>
                    </Grid2>
                    {image.imagePath || image.mobilePath ? (
                        <Grid2 size={{ xs: 12, md: 7 }}>
                            <Picture
                                imagePath={image.imagePath}
                                mobilePath={image.mobilePath}
                                alt={image.imageAlt}
                                mobileAlt={image.mobileAlt}
                                lazyLoad={lazyLoad}
                                fullWidth
                                aspectRatio={image.imageAspectRatio}
                                mobileAspectRatio={image.mobileImageAspectRatio}
                                css={{ borderRadius: '10px' }}
                            />
                        </Grid2>
                    ) : null}
                </Grid2>
            </SectionContainer>
        </LazyLoadContainer>
    );
}
