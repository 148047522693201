import React, { useState } from 'react';
import { v2Colors, getRgbaColor } from '@web-for-marketing/react-ui';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { IconButton } from '@mui/material';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import { CtaBannerCarouselFormContent } from '@models/column';
import { CarouselDotTabs } from '@components/BlogSection/BlogComponents/Home/CarouselDotTabs';
import { RightChevronIconRound } from '@components/IconComponents/RightChevronIconRound';
import { CtaBanner } from './CtaBanner';
import { ctaBannerCarouselStyles as classes } from './styleObjects/CtaBannerCarouselSyles';

export interface CtaBannerCarouselProps {
    content: CtaBannerCarouselFormContent;
    lazyLoad?: boolean;
}

export function CtaBannerCarousel({ content, lazyLoad }: CtaBannerCarouselProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const [activeBanner, setActiveBanner] = useState(0);
    const trackingClass = content.trackingClass;

    if (activeBanner > content.banners.length - 1) {
        setActiveBanner((prevBanner) => prevBanner - 1);
    }

    const handleBannerChange = (bannerIndex: number): void => {
        setActiveBanner(bannerIndex);
    };

    return (
        <div css={classes.parentContainer}>
            <SwipeableViews
                index={activeBanner}
                onChangeIndex={handleBannerChange}
                enableMouseEvents
                disableLazyLoading
                springConfig={{
                    duration: '0.5s',
                    easeFunction: 'ease-out',
                    delay: '0s',
                }}
                css={classes.swipeViewsContainerParent}
                containerStyle={isMobile ? classes.swipeViewsContainer : undefined}
                slideStyle={isMobile ? classes.slide : undefined}
            >
                {content.banners.map((banner, index) => (
                    <div
                        key={`${index}-${banner.imagePath}`}
                        css={classes.bannerContainer(content.height)}
                        data-testid='CtaBannerTestID'
                    >
                        <CtaBanner
                            banner={banner}
                            lazyLoad={lazyLoad}
                            isHidden={index !== activeBanner}
                            trackingClass={trackingClass}
                        />
                    </div>
                ))}
            </SwipeableViews>
            {!isMobile && content.banners.length > 1 ? (
                <div css={classes.paginationControls}>
                    <CarouselDotTabs
                        maxDots={content.banners.length}
                        activeDot={activeBanner}
                        onChangeSlide={setActiveBanner}
                        dotColor={getRgbaColor(v2Colors.core.snow, 0.4)}
                        activeDotColor={v2Colors.core.snow}
                    />
                    <div css={classes.paginationButtons}>
                        <IconButton
                            size='small'
                            aria-label='Go to previous banner'
                            css={classes.iconButton}
                            onClick={() => handleBannerChange(activeBanner - 1)}
                            disabled={activeBanner === 0}
                        >
                            <RightChevronIconRound css={classes.leftChevronIcon} />
                        </IconButton>
                        <IconButton
                            size='small'
                            aria-label='Go to next banner'
                            css={classes.iconButton}
                            onClick={() => handleBannerChange(activeBanner + 1)}
                            disabled={activeBanner === content.banners.length - 1}
                        >
                            <RightChevronIconRound />
                        </IconButton>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
