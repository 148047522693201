import React, { useState, useEffect, useRef } from 'react';
import { Grid2 } from '@mui/material';
import { CircleProgressBar } from './CircleProgressBar';
import { getPercentage, calculateTimeLeft } from '@helpers/countdownHelpers';

interface CountdownProps {
    utcDateTime: string;
    connect2024?: boolean;
}

export function Countdown({ utcDateTime, connect2024 }: CountdownProps): JSX.Element {
    const sizeProps = connect2024 ? { xs: 6, sm: 3 } : undefined;
    const timer = useRef<ReturnType<typeof setTimeout>>();
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        setTimeLeft(calculateTimeLeft(utcDateTime));
    }, [utcDateTime]);

    useEffect(() => {
        if (timer.current) clearTimeout(timer.current);

        if (timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0) {
            timer.current = setTimeout(() => {
                setTimeLeft(calculateTimeLeft(utcDateTime));
            }, 1000);
        }

        return () => {
            if (timer.current) clearTimeout(timer.current);
        };
    }, [timeLeft, utcDateTime]);

    return (
        <Grid2 container justifyContent='center' spacing={2} data-testid='countdownColumn' css={{ width: '100%' }}>
            <Grid2 size={sizeProps}>
                <CircleProgressBar
                    percentage={getPercentage(timeLeft.days, 365)}
                    innerText={timeLeft.days}
                    outerText='Days'
                    connect2024={connect2024}
                />
            </Grid2>
            <Grid2 size={sizeProps}>
                <CircleProgressBar
                    percentage={getPercentage(timeLeft.hours, 24)}
                    innerText={timeLeft.hours}
                    outerText='Hours'
                    connect2024={connect2024}
                />
            </Grid2>
            <Grid2 size={sizeProps}>
                <CircleProgressBar
                    percentage={getPercentage(timeLeft.minutes, 60)}
                    innerText={timeLeft.minutes}
                    outerText='Minutes'
                    connect2024={connect2024}
                />
            </Grid2>
            <Grid2 size={sizeProps}>
                <CircleProgressBar
                    percentage={getPercentage(timeLeft.seconds, 60)}
                    innerText={timeLeft.seconds}
                    outerText='Seconds'
                    connect2024={connect2024}
                />
            </Grid2>
        </Grid2>
    );
}
