import React from 'react';
import { ModularSectionData } from '@models/section/modularSection';
import { LazyLoadContainer } from '@web-for-marketing/react-ui';
import { Grid2, Stack } from '@mui/material';
import { RenderColumnTypes } from './helpers/RenderColumnTypes';
import { SectionColumn, FlexAlignment } from '@models/column';
import { getTotalColumnSpanCount, getColumnCount } from './helpers/modularSectionHelper';
import { breakpoints } from '@web-for-marketing/react-ui';
import { splitPaddingValues } from './helpers/spacingStyleHelpers';
import { getAnimationStyle } from './helpers/AnimationStyleHelper';
import { useAtom } from 'jotai';
import { SectionAnimation, ContentSpacing } from '@models/section';
import { isMobileAtom } from '@atoms/appSettings';
import { isPreviewingAnimationAtom } from '@atoms/animation';
import { SectionContainer } from './SectionContainer';

interface ModularSectionProps {
    sectionData: ModularSectionData;
    lazyLoad: boolean;
    headerSection?: boolean;
    animations?: SectionAnimation;
}

const flexAlignToTextAlign = {
    'flex-start': 'left',
    center: 'center',
    'flex-end': 'right',
} as const;

const classes = {
    module: (horizontalAlignment: FlexAlignment) => ({
        width: '100%',
        height: '100%',
        textAlign: flexAlignToTextAlign[horizontalAlignment] || 'left',
    }),
    mainContainer: (padding: ContentSpacing) => {
        const { paddingTop, paddingBottom } = splitPaddingValues(padding);

        return {
            [`@media (max-width: ${breakpoints.md})`]: {
                overflowX: 'hidden',
            },
            [`@media (min-width: ${breakpoints.md})`]: {
                paddingTop: `${paddingTop}rem`,
                paddingBottom: `${paddingBottom}rem`,
            },
        } as const;
    },
};

export function ModularSection({ sectionData, lazyLoad, headerSection, animations }: ModularSectionProps): JSX.Element {
    const totalColumnSpanCount = getTotalColumnSpanCount(sectionData.modules);
    const spacing = sectionData.spacing || '8rem 0';
    const [isPreviewingAnimation] = useAtom(isPreviewingAnimationAtom);
    const [isMobile] = useAtom(isMobileAtom);
    const modules = sectionData.mobileSwap && isMobile ? sectionData.modules.reverse() : sectionData.modules;

    return (
        <LazyLoadContainer lazyload={lazyLoad} className={animations ? 'animateContainers' : undefined}>
            <SectionContainer
                css={classes.mainContainer(spacing)}
                className={`${getAnimationStyle('sectionAnimation', true, animations, isPreviewingAnimation)}`}
            >
                <Grid2 container columnSpacing='4%' rowSpacing='4rem' columns={totalColumnSpanCount}>
                    {modules.map((module, index) => {
                        // the column span of the module in a 12 column layout
                        const twelveColumnSpan = (12 * module.columnSpan) / totalColumnSpanCount;
                        // the twelveColumnSpan value can be a decimal, so we round it up for when a whole value is needed
                        const roundedTwelveColumnSpan = Math.ceil(twelveColumnSpan);

                        return (
                            <Grid2
                                key={index}
                                data-testid={`modular-column-${index}`}
                                size={{ xs: totalColumnSpanCount, md: module.columnSpan }}
                                className={`${getAnimationStyle(
                                    undefined,
                                    true,
                                    animations,
                                    isPreviewingAnimation,
                                    module.animation
                                )}`}
                            >
                                <Stack
                                    data-testid={`modular-column-content-${index}`}
                                    direction='column'
                                    justifyContent={module.verticalAlignment}
                                    alignItems={module.horizontalAlignment}
                                    css={classes.module(module.horizontalAlignment)}
                                >
                                    <RenderColumnTypes
                                        column={
                                            {
                                                type: module.type,
                                                content: module.content,
                                            } as SectionColumn
                                        }
                                        lazyLoad={lazyLoad}
                                        headerSection={headerSection}
                                        columnCount={getColumnCount(roundedTwelveColumnSpan)}
                                        horizontalAlignment={module.horizontalAlignment}
                                    />
                                </Stack>
                            </Grid2>
                        );
                    })}
                </Grid2>
            </SectionContainer>
        </LazyLoadContainer>
    );
}
