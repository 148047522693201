import React from 'react';
import { pageSectionStyles as classes } from './styleObjects/pageSectionStyles';
import { LazyLoadContainer } from '@web-for-marketing/react-ui';
import { LayeredBackground } from './LayeredBackground/LayeredBackground';
import { useAtom } from 'jotai';
import { isMobileAtom, navHeightAtom } from '@atoms/appSettings';
import { Section } from '@models/section';

interface PageSectionWrapperProps {
    children: React.ReactNode;
    section: Section;
    lazyLoad: boolean;
    isSticky?: boolean;
}

export function PageSectionWrapper({ children, section, lazyLoad, isSticky }: PageSectionWrapperProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const [navHeight] = useAtom(navHeightAtom);
    const { backgroundLayers, mobileBackgroundLayers } = section.jsonContent;

    const layers =
        isMobile && mobileBackgroundLayers && mobileBackgroundLayers.length ? mobileBackgroundLayers : backgroundLayers;

    return (
        <LazyLoadContainer
            component='section'
            lazyload={lazyLoad}
            css={[classes.cardContainer, isSticky ? classes.stickyContainer(navHeight) : undefined]}
        >
            {layers ? <LayeredBackground backgroundLayers={layers} lazyLoad={lazyLoad} /> : null}
            <div css={{ position: 'relative' }}>{children}</div>
        </LazyLoadContainer>
    );
}
