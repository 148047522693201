import React, { useMemo } from 'react';
import { LandingPageHeaderSectionData } from '@models/section/landingPageHeaderSection';
import { SectionContainer } from './SectionContainer';
import { Heading, LazyLoadContainer, Text, v2Colors } from '@web-for-marketing/react-ui';
import { Grid2, Stack } from '@mui/material';
import { customSectionForms } from '@components/EditorFormComponents/helpers/CustomSectionTypes';
import { DownChevronIcon } from '@components/IconComponents/DownChevronIcon';
import { RichTextContent } from '@components/RichTextContent';
import { OutlinedCheckmarkIcon } from '@components/IconComponents/OutlinedCheckmarkIcon';
import { useAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';

interface LandingPageHeaderProps {
    sectionData: LandingPageHeaderSectionData;
    lazyLoad: boolean;
    headerSection?: boolean;
    hasBackground: boolean;
}

const classes = {
    placeHolderBox: (theme: LandingPageHeaderSectionData['theme']) =>
        ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '48rem',
            backgroundColor: theme === 'dark' ? v2Colors.background.darkest : v2Colors.action.active,
            border: `4px dashed ${theme === 'dark' ? v2Colors.text.inversePrimary : v2Colors.text.primary}`,
            width: '100%',
            borderRadius: '8px',
        }) as const,
    list: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    },
    nextSectionContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: '2rem',
    },
    nextSectionLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '7rem',
        height: '7rem',
        padding: 0,
    },
} as const;

export function LandingPageHeader({
    sectionData,
    lazyLoad,
    headerSection,
    hasBackground,
}: LandingPageHeaderProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const CustomSectionForm = useMemo(() => {
        const form = customSectionForms.find((form) => form.value === sectionData.formType);
        return form ? form.component : null;
    }, [sectionData.formType]);
    const { theme, title, subtitle, bulletPoints, params, navigationId, formOrientation } = sectionData;
    const isDarkTheme = theme === 'dark';

    const customForm = CustomSectionForm ? (
        <CustomSectionForm params={params} lazyLoad={lazyLoad} />
    ) : (
        <div css={classes.placeHolderBox(theme)}>
            <Heading variant='h2' color={isDarkTheme ? 'inversePrimary' : 'primary'}>
                Custom Form Section
            </Heading>
            <Text color={isDarkTheme ? 'inversePrimary' : 'primary'}>Edit the section to configure the form</Text>
        </div>
    );

    return (
        <LazyLoadContainer
            css={!hasBackground ? { backgroundColor: v2Colors.background.darker } : undefined}
            lazyload={lazyLoad}
            data-testid='landingPageHeaderBackground'
        >
            <SectionContainer>
                <Grid2
                    container
                    columnSpacing={{ xs: '32px', lg: '64px' }}
                    alignItems='center'
                    flexDirection={{ md: formOrientation === 'right' ? 'row' : 'row-reverse' }}
                    data-testid='orientationWrapper'
                >
                    <Grid2 size={{ xs: 12, md: 7 }}>
                        <Stack spacing={{ xs: '24px', md: '28px' }}>
                            <Heading
                                variant='h1'
                                component={headerSection ? 'h1' : 'h2'}
                                color={isDarkTheme ? 'inversePrimary' : 'primary'}
                            >
                                {title}
                            </Heading>
                            {/* Must ensure two forms with the same id do not appear in the DOM */}
                            {isMobile ? customForm : null}
                            <RichTextContent
                                content={subtitle}
                                variant='h3'
                                weight='medium'
                                color={isDarkTheme ? 'inversePrimary' : 'activeSecondary'}
                            />
                            {bulletPoints.length ? (
                                <Stack component='ul' spacing={{ xs: '24px', md: '28px' }} css={classes.list}>
                                    {bulletPoints.map((bulletPoint, index) => (
                                        <Stack direction='row' component='li' key={index} spacing='8px'>
                                            <OutlinedCheckmarkIcon
                                                color={
                                                    isDarkTheme
                                                        ? v2Colors.text.inversePrimary
                                                        : v2Colors.text.activePrimary
                                                }
                                            />
                                            <RichTextContent
                                                content={bulletPoint}
                                                color={isDarkTheme ? 'inversePrimary' : 'secondary'}
                                            />
                                        </Stack>
                                    ))}
                                </Stack>
                            ) : null}
                        </Stack>
                    </Grid2>
                    {/* Must ensure two forms with the same id do not appear in the DOM */}
                    {!isMobile ? <Grid2 size={{ xs: 12, md: 5 }}>{customForm}</Grid2> : null}
                </Grid2>
                {navigationId ? (
                    <div css={classes.nextSectionContainer}>
                        <a href={`#${navigationId}`} aria-label='Go to next section' css={classes.nextSectionLink}>
                            <DownChevronIcon
                                css={{
                                    '& > path': {
                                        fill: isDarkTheme
                                            ? v2Colors.text.inversePrimary
                                            : v2Colors.text.activeSecondary,
                                    },
                                }}
                            />
                        </a>
                    </div>
                ) : null}
            </SectionContainer>
        </LazyLoadContainer>
    );
}
