import { FormParams } from '@models/form';
import { CustomFormSectionData } from '@models/section/customFormSection';
import { MobileFriendlySectionImage, SectionButtonContentV2, SectionImage } from '@models/section';
import { TextColor } from '@web-for-marketing/react-ui';
import { SectionWidget } from './widget';
import { RichTextListStyle } from './richTextEditor';

export type FlexAlignment = 'flex-start' | 'center' | 'flex-end';

export type SectionColumn =
    | NoneColumn
    | TestimonialColumn
    | TitleBodyButtonsColumn
    | LayeredColumn
    | ImageColumn
    | MediaColumn
    | CardsColumn
    | CtaBannerCarouselColumn
    | HtmlColumn
    | CountdownColumn
    | CustomSectionColumn
    | CustomFormColumn;

export type ColumnSideContent = SectionColumn & {
    align: FlexAlignment;
};

export interface NoneColumn {
    type: 'none';
    content: null;
}

export interface CustomFormColumn {
    type: 'custom-form';
    content: CustomFormSectionData;
}

export interface TitleBodyButtonsColumn {
    type: 'title-body-buttons';
    content: TitleBodyButtonsFormContent;
}

export type CtaBannerHeights = 'short' | 'medium' | 'tall';

interface CtaBannerCarouselColumn {
    type: 'cta-banner-carousel';
    content: CtaBannerCarouselFormContent;
}

export interface CtaBannerCarouselFormContent {
    banners: CtaBannerContent[];
    height: CtaBannerHeights;
    trackingClass?: string;
}

export interface CtaBannerContent {
    title: string;
    description: string;
    linkText: string;
    linkUrl: string;
    ariaLabel: string;
    imagePath: string;
    mobileImagePath: string;
    imageAlt: string;
    mobileAlt?: string;
    decoration?: boolean;
    mobileDecoration?: boolean;
}

export interface TitleBodyButtonsFormContent {
    title?: string;
    body?: string;
    buttons?: SectionButtonContentV2[];
    textColor?: TextColor;
    requestConsent?: boolean;
    checkboxText?: string;
    titleFullWidth?: boolean;
    label?: string;
    widget?: SectionWidget;
    listStyle?: RichTextListStyle;
}

export interface LayeredColumn {
    type: 'layered-content';
    content: LayeredColumnContent;
}

export interface ImageColumn {
    type: 'image';
    content: ImageColumnContent;
}

export interface MediaColumn {
    type: 'video' | 'media';
    content: MediaColumnContent;
}

export interface CardsColumn {
    type: 'hover-card' | 'cards';
    content: CardsColumnContent;
}

export interface HtmlColumn {
    type: 'html';
    content: string;
}

export interface TestimonialColumn {
    type: 'testimonial';
    content: TestimonialColumnContent;
}

export interface CountdownColumn {
    type: 'countdown';
    content: CountdownColumnContent;
}

export interface CustomSectionColumn {
    type: 'custom-section';
    content: CustomSectionColumnContent;
}

export type ColumnCount = 1 | 2 | 3;

export interface LayeredColumnContent {
    title: string;
    body: string;
    subtitle: string;
    linkPath: string;
    linkTarget?: boolean;
    linkText: string;
    ariaLabel: string;
    textColor?: TextColor;
}

export type CardsColumnContent = {
    ariaLabel: string;
    linkPath: string;
    linkTarget: string;
    image: MobileFriendlySectionImage;
}[];

export interface ImageColumnContent {
    image: MobileFriendlySectionImage & {
        imageZoom?: boolean;
    };
}

export type MediaSize = 'small' | 'medium' | 'large';

export interface MediaColumnContent {
    src: string;
    title?: string;
    autoPlay?: boolean;
    mediaSize?: MediaSize;
    mediaSource?: 'gallery' | 'external';
}

interface CountdownColumnContent {
    utcDateTime: string;
}

export interface CustomSectionColumnContent {
    component: string;
    params: FormParams;
}

export interface TestimonialColumnContent {
    image: SectionImage;
    button?: {
        buttonLinkPath: string;
        buttonTarget: boolean;
        buttonText: string;
    };
    body: string;
    ratingEnabled?: boolean;
    rating: number;
    name: string;
    buttonEnabled?: boolean;
    role?: string;
    testimonialStyle?: 'None' | 'Quote' | 'Rating';
    direction?: 'Left' | 'Right';
    showTestimonialBackground?: boolean;
    testimonialBackgroundColor?: TestimonialBackgroundColor;
}

export enum TestimonialBackgroundColor {
    Gray = 'Gray',
    White = 'White',
}
