import React from 'react';
import { Picture, PictureProps } from './Picture';
import { PictureDialog } from './PictureDialog';

const classes = {
    modalContainer: {
        position: 'relative',
        width: '100%',
    },
} as const;

export type PopupPictureProps = PictureProps & { imageZoom?: boolean };

export function PopupPicture({ imageZoom, ...props }: PopupPictureProps): JSX.Element {
    const { imagePath, mobilePath, alt, mobileAlt } = props;

    return imageZoom ? (
        <div css={classes.modalContainer}>
            <Picture {...props} />
            <PictureDialog imagePath={imagePath} mobilePath={mobilePath} alt={alt} mobileAlt={mobileAlt} />
        </div>
    ) : (
        <Picture {...props} />
    );
}
